import React, { useCallback, useEffect, useState } from 'react';
import './EbookStore.css';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { IoCloseSharp } from "react-icons/io5";

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './books/CheckoutForm';
import { getDatabase, onValue, ref } from 'firebase/database';

// "pk_test_51Pm2pOHvmKXk4OG9r64SGbypOeqeS5cZbQCVw3jGUoXNdURHgsZI8KxswAB0bVxeDmHSfDzGHKC89lNR6ppFzYDl00ooJQDh9m" 
// process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY
const stripePromise = loadStripe("pk_live_51Pm2pOHvmKXk4OG9aQJOYdeDahrGgajwVrNlVbeEoVyf3Q5FqrPuaJzeaVIO5B9n932BkaeWhwgAIRgMjgOSgGBJ00tgsjXsVA");

function EbookStore() {
    const [eBooks,setEbooks] = useState([]);
  const [cart, setCart] = useState([]);
  const [isCartVisible, setIsCartVisible] = useState(false);

  const [alertVisible, setAlertVisible] = useState(false);

  const fetchBooksFromDB = useCallback(() => {
    onValue(ref(getDatabase(),`ebooks`),(ebooksSnapshot) => {
        let ebookArray = [];
        ebooksSnapshot.forEach((eBook) => {
            ebookArray.push({
                key:eBook.key,
                ...eBook.val()
            })
        })
        setEbooks(ebookArray.reverse());
    })
  },[eBooks]);

  useEffect(() => {
    fetchBooksFromDB();
  },[])

  const handleAddToCart = (product) => {
    // Show the alert
    setAlertVisible(true);

    // Hide the alert after 3 seconds
    setTimeout(() => {
      setAlertVisible(false);
    }, 3000);

    console.log(`${product.title} added to cart!`);
  };

//   const addToCart = (eBook) => {
//     setCart((prevCart) => [...prevCart, eBook]);
//     handleAddToCart(eBook);
//   };

// Add an item to the cart function
function addToCart(newItem) {
    const existingItem = cart.find(item => item.id === newItem.id);
    if (existingItem) {
      existingItem.quantity += 1; // Increase quantity if item exists;
      handleAddToCart(newItem);
    } else {
      setCart((prevCart) => [...prevCart, { ...newItem, quantity: 1 }]);
      handleAddToCart(newItem);
    }
  }

  const toggleCart = () => {
    setIsCartVisible(!isCartVisible);
  };

  const cartTotal = cart?.reduce((total, item) => total + (item.price * item.quantity), 0).toFixed(2);


  return (
   <div className='body'>
     <div className="app-container">
      <header className="header">
        <h1>eBook Store</h1>
        <p>Programming eBooks for  <span className='current_price'>$4.99</span> <span className='sale'>$49.99</span></p>
        <button className="cart-button" onClick={toggleCart}>
          Cart ({cart.length})
        </button>
      </header>

      <main>
        <section className="carousel-section">
          <h2>Featured eBooks</h2>
          <Carousel autoPlay infiniteLoop>
            {eBooks.map((eBook) => (
              <div key={eBook.id} className="carousel-item">
                {eBook?.image && <img src={eBook.image} alt="" /> }
                <h3>{eBook.title}</h3>
                <p>${eBook.price} <IoCloseSharp /></p>
              </div>
            ))}
          </Carousel>
        </section>

        <section className="ebook-list">
          <h2>Available eBooks</h2>
          <div className="ebook-grid">
            {eBooks.map((eBook) => (
              <div key={eBook.id} className="ebook-card">
                   {eBook?.image && <img src={eBook.image} alt="" style={{width:300,height:'auto'}} /> }
                <h3>{eBook.title}</h3>
                <p>Language: {eBook.language}</p>
                <p>Rating: {eBook.rating} ★</p>
                <p>Price:  <span className='current_price'> ${eBook.price} </span> <span className='sale'>$49.99</span></p>
                <button onClick={() => addToCart(eBook)}>Add to Cart</button>
              </div>
            ))}
          </div>
        </section>
      </main>

      {isCartVisible && (
        <div className="cart-modal">
          <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
          <h2>Your Cart</h2>
          <IoCloseSharp onClick={toggleCart} />
          </div>
          {cart.length === 0 ? (
            <p>Your cart is empty.</p>
          ) : (
            <ul>
              {cart.map((item, index) => (
                <li key={index}>{item.title} - ${item.price} ({item.quantity})</li>
              ))}
            </ul>
          )}
          <Elements stripe={stripePromise}>
        <div className="App">
        <p>Total: ${cartTotal}</p>
            <CheckoutForm cartTotal={cartTotal} cart={cart} />
            </div>
        </Elements>
        </div>
      )}


         {/* Temporary Alert */}
         {alertVisible && (
        <div className="alert-cart">
          Item added to cart!
        </div>
      )}

      <footer className="footer">
        <p>Secure Payment | Instant Download | Trusted by Developers</p>
      </footer>
    </div>
   </div>
  );
}

export default EbookStore;
