import simg from '../images/service-single/img-1.jpg'
import simg2 from '../images/service-single/img-2.jpg'
import simg3 from '../images/service-single/img-3.jpg'
import simg4 from '../images/service-single/img-4.jpg'
import simg5 from '../images/service-single/img-5.jpg'
import simg6 from '../images/service-single/img-3.jpg'


const Languages = [
    {
        Id: '1',
        sImg:simg,
        sTitle:'HTML',
        slug:'Hypertext Markup Language',
        description:`HTML stands for Hyper Text Markup Language.HTML is the standard markup language for creating Web pages
HTML describes the structure of a Web page.`,
        des2:'HTML is the standard markup language for creating Web pages..',
        des3:'HTML is the standard markup language for creating Web pages.',
        icon:'flaticon-gift',
    },
    {
        Id: '2',
        sImg:simg2,
        sTitle:'CSS',
        slug:'Cascading Stylesheet',
        description:`CSS is the language we use to style an HTML document. CSS describes how HTML elements should be displayed.`,
        des2:'CSS is the language we use to style an HTML document..',
        des3:'CSS is the language we use to style an HTML document.',
        icon:'flaticon-suitcase',
    },
    {
        Id: '3',
        sImg:simg3,
        sTitle: 'JavaScript',
        slug:'JavaScript is the programming language of the Web.',
        description:`JavaScript is a great language for coding beginners, we've gathered some of the best learning resources around and built a JavaScript course to help new developers get up and running.`,
        des2:'JavaScript is the programming language of the Web. .',
        des3:'JavaScript is the programming language of the Web.',
        icon:'flaticon-stats',
    },
    {
        Id: '4',
        sImg:simg3,
        sTitle: 'React',
        slug:'The library for web and native user interfaces.',
        description:`React lets you build user interfaces out of individual pieces called components. Whether you work on your own or with thousands of other developers, using React feels the same.`,
        des2:'The library for web and native user interfaces .',
        des3:'The library for web and native user interfaces.',
        icon:'flaticon-stats',
    },
    {
        Id: '5',
        sImg:simg3,
        sTitle: 'Redux',
        slug:'A JS library for predictable and maintainable global state management. ',
        description:`Redux helps you write applications that behave consistently, run in different environments (client, server, and native), and are easy to test.`,
        des2:'A JS library for predictable and maintainable global state management .',
        des3:'A JS library for predictable and maintainable global state management.',
        icon:'flaticon-stats',
    },
    {
        Id: '6',
        sImg:simg3,
        sTitle: 'TypeScript',
        slug:'TypeScript is JavaScript with syntax for types.. ',
        description:`TypeScript is a strongly typed programming language that builds on JavaScript, giving you better tooling at any scale.`,
        des2:'TypeScript is JavaScript with syntax for types.',
        des3:'TypeScript is JavaScript with syntax for types..',
        icon:'flaticon-stats',
    },
    {
        Id: '7',
        sImg:simg3,
        sTitle: 'Nodejs',
        slug:'Nodejs is a server side JavaScript runtime environment. ',
        description:`Node.js® is a free, open-source, cross-platform JavaScript runtime environment that lets developers create servers, web apps, command line tools and scripts.`,
        des2:'Node.js® is a free, open-source, cross-platform JavaScript runtime environment.',
        des3:'Node.js® is a free, open-source, cross-platform JavaScript runtime environment.',
        icon:'flaticon-stats',
    },
    {
        Id: '8',
        sImg:simg3,
        sTitle: 'MongoDB',
        slug:'MongoDB is a source-available, cross-platform, document-oriented database program.',
        description:`MongoDB is a source-available, cross-platform, document-oriented database program. Classified as a NoSQL database product, MongoDB utilizes JSON-like documents with optional schemas.`,
        des2:'MongoDB is a source-available, cross-platform, document-oriented database program.',
        des3:'MongoDB is a source-available, cross-platform, document-oriented database program.',
        icon:'flaticon-stats',
    },
   
]    

export default Languages;