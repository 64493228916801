import React, { useState } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import axios from 'axios';
import './CheckoutForm.css';
import { useNavigate } from 'react-router';

function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const [loading,setLoading] = useState(false);

  const navigate = useNavigate();

  console.log(props);

  const [cartTotal,setCartTotal] = useState(props.cartTotal);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!stripe || !elements) return;

    const cardElement = elements.getElement(CardElement);

    try {
      // Fetch the client secret from the server
      // process.env.REACT_APP_API_URL
      const { data } = await axios.post(`https://stripeserver-e8e41f76c55c.herokuapp.com/create-payment-intent`, {
        amount: cartTotal * 100, // Amount in cents (e.g., $10)
        currency: 'usd',
      });

      const { clientSecret } = data;

      // Confirm the payment
      const paymentResult = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
        },
      });

      if (paymentResult.error) {
        setError(paymentResult.error.message);
        setLoading(false);
      } else if (paymentResult.paymentIntent.status === 'succeeded') {
        setSuccess(true);
        setError(null);
        setLoading(false);
        navigate('/downloadbooks',{ state: { cartTotal, cart:props?.cart } })
      }
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      <button style={{marginTop:5,width:'100%',backgroundColor:'#6495ed'}} type="submit" disabled={!stripe}>
        {loading ? 'Processing' : 'Checkout'}
      </button>
      {error && <div style={{ color: 'red' }}>{error}</div>}
      {success && <div style={{ color: 'green' }}>Payment Successful!</div>}

    </form>
  );
}

export default CheckoutForm;
