import React, { useState } from "react";
import './AddProductForm.css';
import { getDatabase, push, ref } from "firebase/database";

const AddProductForm = () => {
  const [product, setProduct] = useState({
    title: "",
    price: "",
    imageUrl: "",
    language: "",
    rating: "",
    url: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct((prevProduct) => ({
      ...prevProduct,
      [name]: value,
    }));
  };

  const generateRandomId = Math.floor(Math.random() * 1000000);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Product submitted:", product);

    const newProduct = {
        id:generateRandomId,
        title: product.title,
        price: product.price,
        image: product.imageUrl,
        language: product.language,
        rating: product.rating,
        url: product.url,
        date:new Date().toString(),
    }
    
    push(ref(getDatabase(),`ebooks`),newProduct);
    alert("Product added successfully!");
    // Reset form
    setProduct({
      title: "",
      price: "",
      imageUrl: "",
      language: "",
      rating: "",
      url: "",
    });
  };

  return (
    <div style={{ maxWidth: "500px", margin: "0 auto", padding: "20px" }}>
      <h1>Add Product</h1>
      <form onSubmit={handleSubmit}>
        <div style={{ marginBottom: "10px" }}>
          <label>Title:</label>
          <input
            type="text"
            name="title"
            value={product.title}
            onChange={handleChange}
            required
            style={{ width: "100%" }}
          />
        </div>

        <div style={{ marginBottom: "10px" }}>
          <label>Price:</label>
          <input
            type="number"
            name="price"
            value={product.price}
            onChange={handleChange}
            required
            style={{ width: "100%" }}
          />
        </div>

        <div style={{ marginBottom: "10px" }}>
          <label>Image URL:</label>
          <input
            type="text"
            name="imageUrl"
            value={product.imageUrl}
            onChange={handleChange}
            required
            style={{ width: "100%" }}
          />
        </div>

        <div style={{ marginBottom: "10px" }}>
          <label>Language:</label>
          <input
            type="text"
            name="language"
            value={product.language}
            onChange={handleChange}
            required
            style={{ width: "100%" }}
          />
        </div>

        <div style={{ marginBottom: "10px" }}>
          <label>Rating:</label>
          <input
            type="number"
            name="rating"
            step="0.1"
            min="0"
            max="5"
            value={product.rating}
            onChange={handleChange}
            required
            style={{ width: "100%" }}
          />
        </div>

        <div style={{ marginBottom: "10px" }}>
          <label>URL:</label>
          <input
            type="text"
            name="url"
            value={product.url}
            onChange={handleChange}
            required
            style={{ width: "100%" }}
          />
        </div>

        <button type="submit" style={{ padding: "10px 20px", cursor: "pointer" }}>
          Add Product
        </button>
      </form>
    </div>
  );
};

export default AddProductForm;
