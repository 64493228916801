import React, { useState } from 'react';
import db from '../../api/repetitor-uz-a51e5-default-rtdb-export.json';

export default function Coding() {
// console.log(db);
  const [courses,setCourses] = useState([]);
  const [data,setData] = useState(courses);

  const iosDeveloperClass = db.courses['-NWYjSUT6Hil15esASZa'].classes[3];

const VideoPlayer = (props) => {
  return (
    <div style={{ textAlign: "center", marginTop: "20px" }}>
      {/* <video
        width="390"
        height="220"
        controls
        style={{ border: "1px solid #ccc", borderRadius: "8px" }}
        loop
        autoPlay
      >
        <source src={props.source} type="video/mp4" />
        <source src={props.source} type="video/ogg" />
        Your browser does not support the video tag.
      </video> */}
    </div>
  );
};


  return (
    <div style={{
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        width:'100vw',
        height:'100vh',
        textAlign:'center'
    }}>
      <h1>Software Developer Position</h1>
      <h2>Hiring Test</h2>
      <p>2024</p>
      <h3>Exam</h3>
      <VideoPlayer 
      source={iosDeveloperClass.videoUri} />
    </div>
  )
}
