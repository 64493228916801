import React from 'react'
import FlashSaleTop from './FlashSaleTop'
import EbookStore from './EbookStore'

export default function Ecommerce() {
  return (
    <>
    <FlashSaleTop />
    <EbookStore />
    </>
  )
}
