export const codemernllc = {
    title:'MERN CODE LTD',
    description:'MERN CODE LTD',
    technology:'MERN STACK',
    languages:'MongoDB, Express, React and Node',
    established:'08/07/2024',
    address:'135 Madison Ave, New York, NY 10016',
    phone:'+1-917-473-4480',
    email:'merncodeltd@gmail.com',
    altEmail:'admin@merncode.com',
    website:'https://www.merncode.com',
    ein:'99-4334964'
}

