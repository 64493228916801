import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const settings = {
    dots: true,
    arrows: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
};

const Testimonial2 = () => {

    return (
        <section className="testimonials-section-s2 section-padding">
            <h2 className="hidden">Testimonials</h2>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col col-xl-8 col-lg-10">
                        <div className="testimonial-slider">
                            <Slider {...settings}>
                                <div className="testimonials-area">
                                    <p> 
                                    "Your time is limited, don't waste it living someone else's life. Don't be trapped by dogma, which is living the result of other people's thinking.
                                    Don't let the noise of other's opinion drown your own inner voice. And most important, have the courage to follow your heart and intuition, 
                                    they somehow already know what you truly want to become. Everything else is secondary."
                                    </p>
                                    <span className="quoter">Steve Jobs</span>
                                </div>
                                <div className="testimonials-area">
                                    <p> 
                                    "The question I ask myself, like, almost every day is, 'Am I doing the most important thing I could be doing?'... Unless I feel like I'm working on the most important problem that I can help with, then I'm not going to feel good about how I'm spending my time."
                                    </p>
                                    <span className="quoter">Mark Zuckerberg</span>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Testimonial2;